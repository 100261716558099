import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { useLocation } from '@reach/router'
import styled from "styled-components"

const StyledWrapper = styled.div`
  margin: 2rem 5vw;
`

export default function Page() {

    const location = useLocation();
    let country = "CH"
    let lang = "de-CH"

    const url = typeof window !== "undefined" ? window.location.href : "";


    if (url.includes(".com")) {
        country = "DE"
    }

    return (
        <Layout location={country} language={lang}>
            <Seo
                title="Datenschutzerklärung DoryGo & DoryHealth"
                description="Hier finden Sie die Datenschutzvereinbarung der DoryHealth und DoryGo App"
                lang="de"
            />
            <StyledWrapper>
                <h2>D&eacute;claration de protection des donn&eacute;es de DoryHealth AG</h2>
                <h3>Version de cette d&eacute;claration de protection des donn&eacute;es : 7 juillet 2021</h3>


                <div>
                    <ol start="1">
                        <li>
                            <h3>G&eacute;n&eacute;ralit&eacute;s</h3>
                        </li>
                    </ol>
                </div>
                <p>Dans cette d&eacute;claration de protection des donn&eacute;es, nous, DoryHealth AG (ci-apr&egrave;s DoryHealth, nous ou nous-m&ecirc;mes), expliquons comment nous collectons et traitons les donn&eacute;es personnelles. Nous prenons la protection des donn&eacute;es personnelles tr&egrave;s au s&eacute;rieux.</p>
                <p>Cette d&eacute;claration de protection des donn&eacute;es est bas&eacute;e sur le r&egrave;glement g&eacute;n&eacute;ral de l&apos;UE sur la protection des donn&eacute;es (RGPD). Bien que le RGPD soit une r&eacute;glementation de l&apos;Union europ&eacute;enne, il est important pour nous. La loi suisse sur la protection des donn&eacute;es (LPD) est fortement influenc&eacute;e par le droit europ&eacute;en et les entreprises situ&eacute;es en dehors de l&apos;Union europ&eacute;enne ou de l&apos;EEE doivent se conformer au RGPD dans certaines circonstances.</p>
                <div>
                    <ol start="2">
                        <li>
                            <h3>Responsable, d&eacute;l&eacute;gu&eacute; &agrave; la protection des donn&eacute;es et repr&eacute;sentant dans l&apos;UE</h3>
                        </li>
                    </ol>
                </div>

                <p>Le responsable de la collecte, du traitement et de l&apos;utilisation de vos donn&eacute;es personnelles conform&eacute;ment &agrave; l&apos;article 4, point 7, du RGPD est :</p>
                <p>DoryHealth AG</p>
                <p>Hagenholzstrasse 81A</p>
                <p>8050 Zurich</p>
                <p>hello@doryhealth.com</p>
                <p>Vous pouvez contacter le responsable de la protection des donn&eacute;es de DoryHealth &agrave; l&apos;adresse e-mail suivante : &lt;a href=&quot;mailto:datenschutzbeauftragter@doryhealth.com&quot;&gt;datenschutz@doryhealth.com</p>
                <p>Conform&eacute;ment &agrave; l&apos;article 27 du RGPD, nous disposons d&apos;une repr&eacute;sentation pour la protection des donn&eacute;es dans l&apos;Espace &eacute;conomique europ&eacute;en (EEE), y compris l&apos;Union europ&eacute;enne (UE) et la Principaut&eacute; de Liechtenstein, en tant que point de contact suppl&eacute;mentaire pour les autorit&eacute;s de surveillance et les personnes concern&eacute;es pour les demandes en rapport avec le r&egrave;glement g&eacute;n&eacute;ral sur la protection des donn&eacute;es (RGPD) :</p>

                <p>VGS Datenschutzpartner UG</p>
                <p>Am Kaiserkai 69</p>
                <p>20457 Hambourg</p>
                <p>Allemagne</p>
                <p>info@datenschutzpartner.eu</p>
                <div>
                    <ol start="3">
                        <li>
                            <h3>D&eacute;finitions</h3>
                        </li>
                    </ol>
                </div>
                <p>Notre d&eacute;claration de protection des donn&eacute;es doit &ecirc;tre simple et compr&eacute;hensible pour tout le monde. En r&egrave;gle g&eacute;n&eacute;rale, les termes officiels du RGPD sont utilis&eacute;s dans cette d&eacute;claration de protection des donn&eacute;es. Les d&eacute;finitions officielles sont expliqu&eacute;es &agrave; l&apos;article 4 du RGPD.</p>
                <div>
                    <ol start="4">
                        <li>
                            <h3>Collecte de donn&eacute;es &agrave; caract&egrave;re personnel</h3>
                        </li>
                    </ol>
                </div>
                <p>Nous ne traitons que les donn&eacute;es &agrave; caract&egrave;re personnel des clients qui (a) sont saisies par les clients eux-m&ecirc;mes dans l&apos;applicationDoryHealth ou collect&eacute;es par les commandes de m&eacute;dicaments et l&apos;utilisation de la bo&icirc;te &agrave; m&eacute;dicaments, ainsi que (b) sont divulgu&eacute;es par la pharmacie pour la fourniture des prestations.</p>
                <p>En outre, nous traitons diverses donn&eacute;es &agrave; caract&egrave;re personnel que nous avons re&ccedil;ues de personnes physiques impliqu&eacute;es dans le cadre de nos relations commerciales (par ex. collaborateurs, repr&eacute;sentants de pharmacies partenaires, sous-traitants de donn&eacute;es).</p>
                <p>Nous collectons en outre des donn&eacute;es personnelles d&apos;utilisateurs de nos sites web, dont les d&eacute;tails figurent plus loin dans la pr&eacute;sente d&eacute;claration de protection des donn&eacute;es.</p>


                <div>
                    <ol start="5">
                        <li>
                            <h3>Finalit&eacute;s du traitement des donn&eacute;es</h3>
                        </li>
                    </ol>
                </div>
                <p>Nous utilisons les donn&eacute;es personnelles collect&eacute;es en premier lieu pour fournir nos services et ex&eacute;cuter les contrats avec nos clients et partenaires commerciaux, notamment pour mettre &agrave; disposition une solution informatique permettant d&apos;avoir une meilleure vue d&apos;ensemble des m&eacute;dicaments &agrave; prendre, la livraison ponctuelle et tri&eacute;e des m&eacute;dicaments par les pharmacies partenaires et les messages de rappel pour la prise &agrave; temps.</p>
                <p>Ensuite, nous pouvons utiliser vos donn&eacute;es personnelles et celles de tiers, dans la mesure o&ugrave; la loi le permet et o&ugrave; nous le jugeons opportun, pour les finalit&eacute;s suivantes, pour lesquelles nous (et parfois des tiers) avons un int&eacute;r&ecirc;t l&eacute;gitime correspondant &agrave; la finalit&eacute; :</p>

                <p>Vente de nos produits et services ;</p>
                <p>Correspondance et traitement des demandes ;</p>
                <p>Publicit&eacute; et marketing au moyen de bulletins d&apos;information r&eacute;guliers, pour autant que vous ne vous y soyez pas oppos&eacute;. Bien entendu, vous trouverez dans chaque &eacute;dition de notre newsletter un lien de d&eacute;sinscription qui vous permettra de vous opposer &agrave; la r&eacute;ception de futures newsletters ;</p>
                <p>&Eacute;tude et surveillance du march&eacute; ;</p>
                <p>Exercice de droits juridiques et d&eacute;fense en rapport avec des litiges et des proc&eacute;dures administratives ;</p>
                <p>Pr&eacute;venir et &eacute;lucider les d&eacute;lits et autres comportements r&eacute;pr&eacute;hensibles ;</p>
                <p>Assurer et d&eacute;velopper nos op&eacute;rations, y compris notre informatique et nos sites web ;</p>
                <p>Respecter les obligations l&eacute;gales et r&eacute;glementaires.</p>

                <p>Dans la mesure o&ugrave; vous nous avez donn&eacute; votre consentement au traitement de vos donn&eacute;es personnelles &agrave; des fins sp&eacute;cifiques (par exemple lors de l&apos;enregistrement pour recevoir des newsletters ou lors de la conclusion d&apos;un contrat avec nous), nous traiterons vos donn&eacute;es personnelles dans le cadre et sur la base de ce consentement, dans la mesure o&ugrave; nous ne disposons pas d&apos;une autre base juridique et o&ugrave; nous avons besoin d&apos;une telle base. Le consentement donn&eacute; peut &ecirc;tre r&eacute;voqu&eacute; &agrave; tout moment, mais cela ne s&apos;applique pas aux donn&eacute;es personnelles qui ont &eacute;t&eacute; trait&eacute;es avant la r&eacute;vocation.</p>

                <div>
                    <ol start="6">
                        <li>
                            <h3>Partage des donn&eacute;es et destinataires</h3>
                        </li>
                    </ol>
                </div>
                <p>Nous partageons vos donn&eacute;es personnelles avec nos pharmacies partenaires et nos prestataires de services, mais uniquement dans la mesure o&ugrave; cela est n&eacute;cessaire &agrave; la fourniture du service. Aucune donn&eacute;e personnelle n&apos;est partag&eacute;e &agrave; des fins de marketing.</p>
                <p>Vos donn&eacute;es personnelles ne sont donc transmises &agrave; des tiers que dans la mesure o&ugrave; :</p>

                <p>elle est n&eacute;cessaires &agrave; la fourniture du service convenu ;</p>
                <p>nous l&apos;indiquons explicitement dans la pr&eacute;sente d&eacute;claration de protection des donn&eacute;es,</p>
                <p>vous avez donn&eacute; votre consentement explicite &agrave; cet effet,</p>
                <p>la transmission est n&eacute;cessaire &agrave; la constatation, &agrave; l&apos;exercice ou &agrave; la d&eacute;fense de droits en justice ou est dans notre int&eacute;r&ecirc;t l&eacute;gitime pour d&apos;autres raisons et il n&apos;y a pas de raison de supposer que vous avez un int&eacute;r&ecirc;t l&eacute;gitime pr&eacute;pond&eacute;rant &agrave; ce que vos donn&eacute;es ne soient pas transmises,</p>
                <p>il existe une obligation l&eacute;gale pour la transmission.</p>

                <p>Pour la fourniture de nos prestations et le d&eacute;roulement de nos services, nous faisons appel &agrave; des prestataires de services externes que nous s&eacute;lectionnons et mandatons avec soin. Ceux-ci sont tenus de respecter nos instructions. En outre, nous avons conclu avec eux, si n&eacute;cessaire, des contrats de traitement des commandes conform&eacute;ment &agrave; l&apos;article 28 du RGPD. Nous faisons appel &agrave; des prestataires de services pour l&apos;h&eacute;bergement de sites web, l&apos;envoi d&apos;e-mails, l&apos;exploitation, le d&eacute;veloppement, la maintenance et l&apos;entretien de nos syst&egrave;mes informatiques et la gestion des paiements.</p>

                <p>Les donn&eacute;es relatives &agrave; la sant&eacute; sont exclusivement stock&eacute;es et trait&eacute;es dans l&apos;UE ou en Suisse.</p>

                <div>
                    <ol start="7">
                        <li>
                            <h3>Traitement des donn&eacute;es &agrave; caract&egrave;re personnel lors de la visite de notre site web</h3>
                        </li>
                    </ol>
                </div>
                <p>Lors de la consultation de notre site Internet <a href="http://www.doryhealth.com">www.doryhealth.com</a> ou <a href="http://www.doryhealth.ch">www.doryhealth.ch</a> ou <a href="http://www.doryhealth.de">www.doryhealth.de</a> ou <a href="http://www.dorygo.com">www.dorygo.com</a> ou <a href="http://www.dorygo.ch">www.dorygo.ch</a> ou <a href="http://www.dorygo.de">www.dorygo.de</a>; titre purement informatif, nous collectons les donn&eacute;es &agrave; caract&egrave;re personnel que votre navigateur transmet &agrave; notre serveur.</p>
                <p>Les donn&eacute;es mentionn&eacute;es sont trait&eacute;es par nos soins aux fins suivantes :</p>

                <p>Garantir une connexion sans probl&egrave;me du site web,</p>
                <p>Garantir une utilisation confortable de notre site web,</p>
                <p>&eacute;valuer la s&eacute;curit&eacute; et la stabilit&eacute; du syst&egrave;me, ainsi que</p>
                <p>&agrave; d&apos;autres fins administratives.</p>
                <p>Nous n&apos;utilisons en aucun cas les donn&eacute;es collect&eacute;es dans le but de tirer des conclusions sur votre personne. Le cas &eacute;ch&eacute;ant, les donn&eacute;es sont trait&eacute;es sous forme anonyme &agrave; des fins statistiques. Ces donn&eacute;es ne sont &agrave; aucun moment enregistr&eacute;es avec d&apos;autres donn&eacute;es personnelles de l&apos;utilisateur, ni compar&eacute;es &agrave; d&apos;autres bases de donn&eacute;es ou transmises &agrave; des tiers.</p>

                <p>En outre, nous utilisons des cookies et des services d&apos;analyse lors de la visite de notre site web. Vous trouverez de plus amples explications &agrave; ce sujet dans la pr&eacute;sente d&eacute;claration de protection des donn&eacute;es.</p>

                <div>
                    <ol start="8">
                        <li>
                            <h3>Traitement des donn&eacute;es personnelles dans le cadre de la prise de contact</h3>
                        </li>
                    </ol>
                </div>
                <p>Vous pouvez prendre contact avec DoryHealth ou nos pharmacies partenaires par e-mail &agrave; l&apos;adresse e-mail publi&eacute;e sur notre site web ou notre application ou via le formulaire de contact pr&eacute;d&eacute;fini.</p>
                <p>Si vous utilisez l&apos;un des moyens de contact mentionn&eacute;s, les donn&eacute;es &agrave; caract&egrave;re personnel que vous nous transmettez (p. ex. nom, pr&eacute;nom, adresse), au minimum l&apos;adresse e-mail, ainsi que les informations contenues dans l&apos;e-mail ou dans le formulaire de contact sont enregistr&eacute;es dans le but de prendre contact avec vous et de traiter votre demande. Nous supprimons les donn&eacute;es g&eacute;n&eacute;r&eacute;es dans ce contexte apr&egrave;s que leur stockage n&apos;est plus n&eacute;cessaire ou limitons leur traitement en cas d&apos;obligations l&eacute;gales de conservation.</p>

                <div>
                    <ol start="9">
                        <li>
                            <h3>Traitement des donn&eacute;es &agrave; caract&egrave;re personnel dans le cadre de notre service et de notre application mobile</h3>
                        </li>
                    </ol>
                </div>

                <p>Nous proposons une application mobile et des prestations de service. Le service est fourni en collaboration avec des pharmacies partenaires enregistr&eacute;es chez nous. Pour ce faire, d&apos;autres donn&eacute;es &agrave; caract&egrave;re personnel doivent &ecirc;tre collect&eacute;es, comme par exemple des donn&eacute;es de sant&eacute; ou des informations de paiement. DoryHealth est conjointement responsable des donn&eacute;es personnelles transmises avec la pharmacie partenaire concern&eacute;e.</p>
                <p>Pour l&apos;utilisation de nos services, des donn&eacute;es relatives &agrave; la sant&eacute; sont trait&eacute;es. Celles-ci appartiennent &agrave; la cat&eacute;gorie particuli&egrave;re des donn&eacute;es &agrave; caract&egrave;re personnel au sens de l&apos;article 9, paragraphe 1 du RGPD. Nous ne traiterons cette cat&eacute;gorie particuli&egrave;re de donn&eacute;es &agrave; caract&egrave;re personnel que dans la mesure o&ugrave; vous avez conclu un contrat avec nous pour l&apos;utilisation du service et que vous avez ainsi express&eacute;ment donn&eacute; votre consentement &agrave; cette utilisation.</p>

                <p>Si vous faites appel &agrave; notre service ou &agrave; l&apos;application mobile, les donn&eacute;es &agrave; caract&egrave;re personnel suivantes seront trait&eacute;es.</p>

                <p>Pr&eacute;nom et nom</p>
                <p>Adresse</p>
                <p>Genre</p>
                <p>Date de naissance</p>
                <p>Num&eacute;ro de t&eacute;l&eacute;phone/num&eacute;ro de portable</p>
                <p>E-mail</p>
                <p>Donn&eacute;es des ordonnances de m&eacute;dicaments (par ex. m&eacute;dicaments, m&eacute;decin prescripteur)</p>
                <p>Donn&eacute;es sur les m&eacute;dicaments envoy&eacute;s, y compris la pharmacie responsable</p>
                <p>Images des ordonnances de m&eacute;dicaments (si t&eacute;l&eacute;charg&eacute;es par vos soins)</p>
                <p>Autres donn&eacute;es de sant&eacute; &eacute;ventuellement communiqu&eacute;es par l&apos;utilisateur ou la pharmacie</p>
                <p>Donn&eacute;es relatives &agrave; l&apos;assurance maladie</p>
                <p>Donn&eacute;es relatives au moment de la prise de m&eacute;dicaments</p>

                <p>La collecte, le traitement et l&apos;utilisation des donn&eacute;es ont lieu aux fins suivantes :</p>

                <p>Fournir l&apos;application, les fonctions de service et la livraison de m&eacute;dicaments.</p>
                <p>Am&eacute;lioration de notre application et de nos services</p>
                <p>Traitement pour des &eacute;valuations anonymis&eacute;es &agrave; des fins scientifiques, statistiques et analytiques, y compris pour le traitement des donn&eacute;es personnelles.</p>

                <p>Fournir l&apos;application, les fonctions de service et la livraison de m&eacute;dicaments</p>
                <p>Am&eacute;lioration de notre application et de nos services</p>
                <p>Traitement pour des &eacute;valuations anonymes &agrave; des fins scientifiques, statistiques et analytiques, y compris le d&eacute;veloppement de nouvelles proc&eacute;dures de diagnostic bas&eacute;es sur des donn&eacute;es &amp;ndash ; dans chaque cas si et dans la mesure o&ugrave; la loi le permet.</p>
                <p>Traitement &agrave; des fins de facturation</p>

                <p>La facturation de nos services est effectu&eacute;e par le prestataire de services de paiement externe Stripe Payments Europe Limited (&bdquo;Stripe&ldquo;). DoryHealth AG et Stripe ont conclu un contrat de traitement des donn&eacute;es de commande.</p>
                <p>Pour le traitement des paiements ou l&apos;adaptation de l&apos;abonnement, DoryHealth AG transmet &agrave; Stripe les donn&eacute;es de transaction suivantes :</p>

                <p>Pr&eacute;nom et nom de famille</p>
                <p>E-mail</p>
                <p>Num&eacute;ro de client</p>
                <p>Le service choisi</p>
                <p>Montant de la transaction</p>
                <p>La date de d&eacute;but souhait&eacute;e</p>

                <p>Stripe n&apos;a &agrave; aucun moment acc&egrave;s &agrave; vos donn&eacute;es de sant&eacute;.</p>

                <div>
                    <ol start="10">
                        <li>
                            <h3>Traitement des donn&eacute;es &agrave; caract&egrave;re personnel dans le cadre de l&apos;utilisation de notre Webapp</h3>
                        </li>
                    </ol>
                </div>
                <p>Lors de l&apos;utilisation de la Webapp (par exemple le syst&egrave;me de pharmacie), les donn&eacute;es suivantes sont collect&eacute;es.</p>

                <p>Pr&eacute;nom et nom de l&apos;utilisateur</p>
                <p>Adresse e-mail</p>
                <p>Quand l&apos;app est utilis&eacute;e</p>
                <p>Informations sur l&apos;acc&egrave;s aux donn&eacute;es ou la modification des donn&eacute;es, y compris l&apos;horodatage.</p>
                <p>Adresse IP</p>
                <p>Coordonn&eacute;es du m&eacute;decin prescripteur de m&eacute;dicaments</p>


                <p>En outre, des donn&eacute;es et des cookies sont &eacute;ventuellement utilis&eacute;s de mani&egrave;re analogue &agrave; notre site web.</p>

                <div>
                    <ol start="11">
                        <li>
                            <h3>S&eacute;curit&eacute; des donn&eacute;es</h3>
                        </li>
                    </ol>
                </div>
                <p>Conform&eacute;ment &agrave; l&apos;art. 32 du RGPD, nous utilisons au sein du site web le proc&eacute;d&eacute; SSL (Secure-Socket Layer) largement r&eacute;pandu en combinaison avec le niveau de cryptage le plus &eacute;lev&eacute; support&eacute; par votre navigateur web.</p>
                <p>Nous utilisons par ailleurs des mesures de s&eacute;curit&eacute; techniques et organisationnelles appropri&eacute;es pour prot&eacute;ger vos donn&eacute;es contre toute manipulation accidentelle ou intentionnelle, contre la perte partielle ou totale, la destruction ou l&apos;acc&egrave;s non autoris&eacute; par des tiers. Nos mesures de s&eacute;curit&eacute; sont continuellement am&eacute;lior&eacute;es en fonction de l&apos;&eacute;volution technologique.</p>

                <div>
                    <ol start="12">
                        <li>
                            <h3>Dur&eacute;e de conservation des donn&eacute;es &agrave; caract&egrave;re personnel</h3>
                        </li>
                    </ol>
                </div>
                <p>Nous traitons et enregistrons vos donn&eacute;es personnelles aussi longtemps que cela est n&eacute;cessaire pour remplir nos obligations contractuelles et l&eacute;gales ou pour atteindre les objectifs poursuivis par le traitement, c&apos;est-&agrave;-dire par exemple pour la dur&eacute;e totale de la relation commerciale ainsi qu&apos;au-del&agrave;, conform&eacute;ment aux obligations l&eacute;gales de conservation et de documentation. Dans ce contexte, il est possible que les donn&eacute;es personnelles soient conserv&eacute;es pendant la p&eacute;riode au cours de laquelle des droits peuvent &ecirc;tre exerc&eacute;s &agrave; l&apos;encontre de notre entreprise et dans la mesure o&ugrave; nous y sommes contraints par ailleurs par la loi ou si des int&eacute;r&ecirc;ts commerciaux l&eacute;gitimes l&apos;exigent (par exemple &agrave; des fins de preuve et de documentation). D&egrave;s que vos donn&eacute;es personnelles ne sont plus n&eacute;cessaires aux fins susmentionn&eacute;es, elles sont en principe et dans la mesure du possible supprim&eacute;es ou rendues anonymes. Pour les donn&eacute;es d&apos;exploitation (p. ex. journaux de syst&egrave;me, logs), des d&eacute;lais de conservation plus courts de douze mois ou moins s&apos;appliquent en principe.</p>

                <div>
                    <ol start="13">
                        <li>
                            <h3>Hubspot - Traitement des demandes d&apos;assistance</h3>
                        </li>
                    </ol>
                </div>
                <p>Si vous nous envoyez une demande par e-mail ou si vous nous contactez via notre plate-forme, nous utilisons le syst&egrave;me de tickets de Hub Spot, une plate-forme de service client de Hubspot Inc, 25 First Street, Cambridge, MA 02141 USA.</p>
                <p>Afin de r&eacute;pondre aux demandes des utilisateurs, des donn&eacute;es n&eacute;cessaires telles que le nom, le pr&eacute;nom, le num&eacute;ro de t&eacute;l&eacute;phone et l&apos;adresse e-mail sont collect&eacute;es.</p>
                <p>Hubspot travaille pour nous en tant que sous-traitant conform&eacute;ment &agrave; l&apos;article 28 du RGPD et nous avons oblig&eacute; Hubspot par contrat &agrave; respecter un niveau de protection ad&eacute;quat.</p>

                <div>
                    <ol start="14">
                        <li>
                            <h3>Analyse web et suivi publicitaire sur notre site web</h3>
                        </li>
                    </ol>
                </div>
                <p>Nous utilisons certains services et technologies de Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland (&bdquo;Google&ldquo;). Google utilise ce que l&apos;on appelle des cookies. Il s&apos;agit de fichiers texte qui sont enregistr&eacute;s sur votre ordinateur et qui permettent d&apos;analyser votre utilisation du site web. Les informations g&eacute;n&eacute;r&eacute;es par les cookies concernant votre utilisation de ce site web sont g&eacute;n&eacute;ralement transmises &agrave; un serveur de Google aux &Eacute;tats-Unis et y sont enregistr&eacute;es. Un transfert de donn&eacute;es &agrave; caract&egrave;re personnel vers les &Eacute;tats-Unis n&apos;a lieu que dans le respect de garanties appropri&eacute;es au sens des articles 46 et suivants du RGPD. RGPD.</p>
                <p>Nous utilisons sur notre site Internet Google Analytics, Google Tag Manager, Google AdWords-Conversion-Tracking, Microsoft Clarity, ainsi que Facebook Pixel pour analyser l&apos;utilisation de notre site Internet et le succ&egrave;s de nos mesures publicitaires.</p>
                <p>Dans le cadre du suivi des conversions de Google AdWords, Google enregistre la r&eacute;alisation de certains objectifs, par exemple la conclusion d&apos;un abonnement, sur notre site web et peut, &agrave; l&apos;aide du cookie pr&eacute;alablement d&eacute;fini, attribuer les annonces publicitaires sur lesquelles on a cliqu&eacute; auparavant.</p>
                <p>Nous utilisons Google Analytics uniquement avec l&apos;anonymisation IP activ&eacute;e. L&apos;adresse IP des utilisateurs est alors raccourcie, ce qui permet d&apos;exclure toute possibilit&eacute; d&apos;identification personnelle.</p>
                <p>Utilisation de cookies</p>
                <p>En plus de ce qui pr&eacute;c&egrave;de, des cookies sont enregistr&eacute;s sur votre ordinateur lorsque vous utilisez notre site web.</p>
                <p>Nous utilisons des cookies pour pouvoir vous identifier lors de vos visites ult&eacute;rieures, si vous disposez d&apos;un compte chez nous. Dans le cas contraire, vous devriez vous connecter &agrave; nouveau &agrave; chaque visite.</p>
                <p>Les cookies servent en partie &agrave; simplifier les processus du site web par l&apos;enregistrement des param&egrave;tres (par ex. la mise &agrave; disposition d&apos;options d&eacute;j&agrave; s&eacute;lectionn&eacute;es). Si des donn&eacute;es personnelles sont &eacute;galement trait&eacute;es par certains cookies que nous avons mis en place, le traitement a lieu pour l&apos;ex&eacute;cution du contrat ou pour pr&eacute;server nos int&eacute;r&ecirc;ts l&eacute;gitimes &agrave; la meilleure fonctionnalit&eacute; possible du site web ainsi qu&apos;&agrave; une organisation conviviale et efficace de la visite du site.</p>
                <p>Vous pouvez emp&ecirc;cher l&apos;enregistrement des cookies en configurant votre logiciel de navigation en cons&eacute;quence. Nous attirons toutefois votre attention sur le fait que, dans ce cas, il se peut que vous ne puissiez pas utiliser toutes les fonctions de ce site web dans leur int&eacute;gralit&eacute;. Vous pouvez en outre emp&ecirc;cher la saisie des donn&eacute;es g&eacute;n&eacute;r&eacute;es par les cookies et se rapportant &agrave; votre utilisation du site web (y compris votre adresse IP) ainsi que le traitement de ces donn&eacute;es par Google en t&eacute;l&eacute;chargeant et en installant le plug-in de navigateur disponible &agrave; l&apos;adresse URL http://tools.google.com/dlpage/gaoptout?hl=de.</p>
                <div>
                    <ol start="15">
                        <li>
                            <h3>Services utilis&eacute;s dans notre application mobile et notre application web</h3>
                        </li>
                    </ol>
                </div>

                <p>Google Firebase</p>
                <p>Notre application et notre webapp utilisent la technologie Google Firebase de l&apos;entreprise Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, &Eacute;tats-Unis (&bdquo;Firebase&ldquo;).</p>
                <p>Nous avons conclu avec Google un contrat de traitement des commandes avec des clauses contractuelles standard de la Commission europ&eacute;enne, dans lesquelles Google s&apos;engage &agrave; traiter les donn&eacute;es des utilisateurs uniquement selon nos instructions et &agrave; respecter le niveau de protection des donn&eacute;es de l&apos;UE.</p>
                <p>Nous utilisons Firebase Cloude Firestore et Firebase Cloud Functions de Google pour stocker ou traiter les donn&eacute;es collect&eacute;es dans l&apos;application mobile et l&apos;application web. Le stockage et le traitement des donn&eacute;es de sant&eacute; ont lieu exclusivement dans l&apos;Union europ&eacute;enne ou en Suisse.</p>
                <p>Nous utilisons Google Firebase Authentication pour la connexion ou l&apos;authentification des utilisateurs autoris&eacute;s. Pour ce faire, certaines donn&eacute;es telles que l&apos;adresse e-mail ou votre num&eacute;ro de client interne sont trait&eacute;es par Google. Ce traitement peut notamment avoir lieu aux &Eacute;tats-Unis. Aucune donn&eacute;e de sant&eacute; n&apos;est transmise.</p>
                <p>Google Analytics for Firebase permet d&apos;analyser de mani&egrave;re anonyme l&apos;utilisation de notre offre. Cela nous permet par exemple de comprendre &agrave; quelle fr&eacute;quence les fonctions sont utilis&eacute;es et combien de temps il faut par exemple pour soumettre une nouvelle ordonnance. Cela nous permet de d&eacute;couvrir les points faibles de l&apos;application et de les am&eacute;liorer.</p>
                <p>Nous utilisons Google Firebase Crashlytics / Firebase Crash Reporting pour collecter et analyser les donn&eacute;es relatives aux crashs de l&apos;application mobile. Cela nous permet de d&eacute;tecter et de corriger rapidement les erreurs. Nous pouvons ainsi am&eacute;liorer la stabilit&eacute; de notre application mobile.</p>

                <p>Fonction de recherche de la webapp</p>
                <p>Dans la Webapp pour les pharmaciens, nous utilisons les services d&apos;Algolia inc, 301 Howard St, San Francisco, CA 94105 (USA), pour rechercher des patients et des m&eacute;decins. Le traitement des donn&eacute;es est effectu&eacute; dans l&apos;Union europ&eacute;enne.</p>
                <p>Nous avons conclu avec Algolia un contrat de sous-traitance avec des clauses contractuelles types de la Commission europ&eacute;enne, dans lesquelles Algolia s&apos;engage &agrave; traiter les donn&eacute;es des utilisateurs uniquement selon nos instructions et &agrave; respecter le niveau de protection des donn&eacute;es de l&apos;UE.</p>


                <div>
                    <ol start="16">
                        <li>
                            <h3>Vos droits</h3>
                        </li>
                    </ol>
                </div>
                <p>Vous avez le droit :</p>

                <p>conform&eacute;ment &agrave; l&apos;art. 15 RGPD, de demander des informations sur vos donn&eacute;es personnelles que nous traitons ;</p>
                <p>conform&eacute;ment &agrave; l&apos;art. 16 RGPD, d&apos;exiger sans d&eacute;lai la rectification des donn&eacute;es &agrave; caract&egrave;re personnel vous concernant qui sont inexactes ou incompl&egrave;tes et que nous avons enregistr&eacute;es ;</p>
                <p>conform&eacute;ment &agrave; l&apos;art. 17 RGPD, d&apos;exiger la suppression de vos donn&eacute;es personnelles enregistr&eacute;es chez nous, dans la mesure o&ugrave; leur conservation n&apos;est pas n&eacute;cessaire &agrave; l&apos;ex&eacute;cution de nos obligations contractuelles et l&eacute;gales, &agrave; l&apos;ex&eacute;cution d&apos;une obligation l&eacute;gale, &agrave; l&apos;exercice ou &agrave; la d&eacute;fense de droits en justice ou, sinon, aux fins poursuivies par le traitement ;</p>
                <p>conform&eacute;ment &agrave; l&apos;article 18 du RGPD, de demander la limitation du traitement de vos donn&eacute;es personnelles dans la mesure o&ugrave; vous contestez l&apos;exactitude des donn&eacute;es, o&ugrave; le traitement est illicite et o&ugrave; nous n&apos;avons plus besoin des donn&eacute;es ;</p>
                <p>conform&eacute;ment &agrave; l&apos;article 20 du RGPD, de recevoir les donn&eacute;es &agrave; caract&egrave;re personnel que vous nous avez fournies dans un format structur&eacute;, couramment utilis&eacute; et lisible par machine, ou de demander leur transmission &agrave; un autre responsable du traitement ;</p>


                <p>conform&eacute;ment &agrave; l&apos;article 7, paragraphe 3, du RGPD, de r&eacute;voquer &agrave; tout moment, avec effet pour l&apos;avenir, le consentement que vous avez donn&eacute; au traitement des donn&eacute;es. En cas de r&eacute;vocation, nous supprimerons imm&eacute;diatement les donn&eacute;es concern&eacute;es, &agrave; moins qu&apos;un traitement ult&eacute;rieur ne puisse &ecirc;tre fond&eacute; sur une base juridique permettant un traitement sans consentement. La r&eacute;vocation du consentement n&apos;affecte pas la l&eacute;galit&eacute; du traitement effectu&eacute; sur la base du consentement jusqu&apos;&agrave; la r&eacute;vocation et de d&eacute;poser une plainte aupr&egrave;s d&apos;une autorit&eacute; de contr&ocirc;le, conform&eacute;ment &agrave; l&apos;article 77 du RGPD.</p>


                <p>Veuillez toutefois noter que nous nous r&eacute;servons le droit d&apos;imposer des restrictions l&eacute;gales de notre part, par exemple si nous sommes oblig&eacute;s de conserver ou de traiter certaines donn&eacute;es personnelles, si nous avons un int&eacute;r&ecirc;t pr&eacute;pond&eacute;rant ou si nous avons besoin des donn&eacute;es personnelles pour faire valoir des droits. Si l&apos;exercice de certains droits entra&icirc;ne des frais, nous vous en informerons au pr&eacute;alable.</p>
                <p>L&apos;exercice des droits mentionn&eacute;s suppose en principe que vous puissiez prouver votre identit&eacute; (par exemple par la copie d&apos;une pi&egrave;ce d&apos;identit&eacute;). Pour faire valoir ces droits, veuillez nous contacter par l&apos;interm&eacute;diaire de notre d&eacute;l&eacute;gu&eacute; &agrave; la protection des donn&eacute;es.</p>

                <div>
                    <ol start="17">
                        <li>
                            <h3>Modifications de notre d&eacute;claration de protection des donn&eacute;es</h3>
                        </li>
                    </ol>
                </div>
                <p>Nous nous r&eacute;servons le droit de modifier la pr&eacute;sente d&eacute;claration de protection des donn&eacute;es. La version actuelle publi&eacute;e sur notre site web fait foi. Si la d&eacute;claration de confidentialit&eacute; fait partie d&apos;un accord en cours avec vous, nous vous informerons par e-mail ou par tout autre moyen appropri&eacute; en cas de modification.</p>

            </StyledWrapper>
        </Layout >
    )
}
